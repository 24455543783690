'use strict';

(function () {
  var body = document.querySelector('body');
  var menuOpenButton = document.querySelector('.header__nav-opening-btn');
  var menuCloseButton = document.querySelector('.header__nav-closing-btn');

  var toggleMenu = function () {
    menuOpenButton.classList.toggle('js-active');
    menuCloseButton.classList.toggle('js-active');
    body.classList.toggle('js-no-scroll');
  };

  var isRetina = function () {
    return ((window.matchMedia && (window.matchMedia('only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)').matches || window.matchMedia('only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)').matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 2));
  };

  var addSlides = function () {
    var slides = document.querySelectorAll('.intro__slide');
    var imagePath;
    var imageUrl;
    var slide;

    for (var i = 0; i < slides.length; i++) {
      imagePath = 'img/slider/slide' + (i + 1) + '.jpg';
      if (window.matchMedia('max-width: 768px').matches && !isRetina()) {
        imagePath = 'img/slider/slide' + (i + 1) + '-mob.jpg';
      } else if (isRetina() && window.matchMedia('min-width: 769px').matches) {
        imagePath = 'img/slider/slide' + (i + 1) + '@2x.jpg';
      }

      imageUrl = 'url(' + imagePath + ');';
      slide = slides[i];
      slide.setAttribute('style', 'background-image:' + imageUrl);
    }
  };

  addSlides();

  body.addEventListener('click', function (evt) {
    var menu = document.querySelector('.header__top');
    var target = evt.target;
    var clickedTargetDataset = target.dataset.target;

    if (target.classList.contains('header__nav-opening-btn') || target.classList.contains('header__nav-closing-btn')) {
      toggleMenu();
    }

    if (target.tagName === 'A' && clickedTargetDataset) {
      evt.preventDefault();
      var targetClass = '.' + clickedTargetDataset;
      var scrollTargetPosition = document.querySelector(targetClass).offsetTop;
      if (window.matchMedia("(max-width: 767px)").matches && target.classList.contains('header__nav-link')) {
        toggleMenu();
      } else {
        if (clickedTargetDataset === 'activities__item--employment' || clickedTargetDataset === 'activities__item--bonuses') {
          scrollTargetPosition = document.querySelector('.activities').offsetTop;
        }
        scrollTargetPosition -= menu.offsetHeight;
      }
      target.blur();
      window.scrollTo({
        top: scrollTargetPosition,
        behavior: "smooth"
      });
    }
  });

  var swiper = new Swiper('.swiper-container', {
    autoplay: {
      delay: 5000,
    },
  });
})();